(function () {
  'use strict';

  /* @ngdoc object
   * @name referees.referee
   * @description
   *
   */
  angular
    .module('neo.home.refereeNewInvoice',['ui.router','neo.home.refereeNewInvoice.newline','neo.home.refereeNewInvoice.line','neo.home.refereeNewInvoice.invoiceslist',
    'neo.home.refereeNewInvoice.facturar','neo.home.refereeNewInvoice.invoicesgroup','neo.home.refereeNewInvoice.invoicegroup.invoice']);
}());
